import { graphql } from 'gatsby'
import React from 'react'
import { SliderBannerBlock } from '@/components/blocks/SliderBannerBlock'

import {
  AdvertiseBlock,
  ColumnsBlock,
  ContentBlock,
  FeaturesBlock,
  PostsBlock,
  ProductsBlock,
  PromoBannerBlock,
  SearchBlock,
} from 'starterComponents'

const Page = (props) => {
  const {
    data: {
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  return (
    <>
      {flexibleLayouts?.map((block, index) => {
        switch (block.__typename) {
          case 'WpPage_Flexlayouts_FlexibleLayouts_ColumnsBlock':
            return <ColumnsBlock key={index} {...block} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_ContentBlock':
            return <ContentBlock key={block.__typename} {...block} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_FeaturesBlock':
            return <FeaturesBlock key={index} {...block} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_PromoBannerBlock':
            return <PromoBannerBlock key={index} {...block} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_SliderBannerGroupBlock':
            return <SliderBannerBlock key={index} {...block} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_SearchBlock':
            return <SearchBlock {...block} key={index} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_PostsBlock':
            return <PostsBlock {...block} key={index} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_ProductsBlock':
            return <ProductsBlock {...block} key={index} />
          case 'WpPage_Flexlayouts_FlexibleLayouts_AdvertiseBlock':
            return <AdvertiseBlock {...block} key={index} />
          default:
            return ''
        }
      })}
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query WpHomePageTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          __typename
          ...columnsBlockFragment
          ...contentBlockFragment
          ...featuresBlockFragment
          ...imageBlockFragment
          ...promoBannerBlockFragment
          ...sliderBannerBlockFragment
          ...searchBlockFragment
          ...postsBlockFragment
          ...productsBlockFragment
          ...advertiseBlockFragment
        }
      }
    }
  }
`
