import React from 'react'
import { graphql } from 'gatsby'
import ContentSlider from '../ui-components/content-slider'
import { PromoBannerBlock } from './PromoBannerBlock'

export const fragment = graphql`
  fragment sliderBannerBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_SliderBannerGroupBlock {
    delay
    slides {
      bannertitle
      bannersubtitle
      bannerContent
      bannerbutton {
        target
        title
        url
      }
      bannerimage {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export const SliderBannerBlock = ({ delay, ...props }) => {
  return (
    <ContentSlider delay={delay}>
      {props.slides.map((promoBannerContent, index) => {
        promoBannerContent.id = index
        return <PromoBannerBlock key={index} {...promoBannerContent} />
      })}
    </ContentSlider>
  )
}
